<template>
  <div >
<b-row>
  <b-col md="6">
    <b-card
        no-body

        class="card-statistics border-success" >
      <b-card-header >
        <b-card-title >
          <b-badge
              variant="primary"
              pill
              class="badge-round"
          >
          <div v-if="awards.stage === 1">
            岗位任职条件审核
          </div>
          <div v-if="awards.stage === 2">
            初评阶段投票阶段
          </div>
          <div v-if="awards.stage === 3">
            终评阶段投票阶段
          </div>
          <div v-if="awards.stage === 4">
            确定拟聘推荐人选
          </div>
          </b-badge>
        </b-card-title>
        <b-card-text class="font-small-2 mr-25 mb-0">
          <b-button
              variant="outline-success"
              pill

          >
            请投票
          </b-button>
        </b-card-text>
      </b-card-header>
      <b-card-body class="statistics-body">
        1
        <b-row>
          <b-col >
            <b-media no-body>
              <b-media-aside
                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="success"
                >
                  <feather-icon
                      size="24"
                      icon="UsersIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                3
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  人数
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col>
            <b-media no-body>
              <b-media-aside
                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="info"
                >
                  <feather-icon
                      size="24"
                      icon="UserCheckIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                 2
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  名额
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col>
            <b-media no-body>
              <b-media-aside
                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="danger"
                >
                  <feather-icon
                      size="24"
                      icon="ThumbsUpIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                 1
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  余票
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>


      </b-card-body>
    </b-card>
  </b-col>



    <b-col md="3">
      <b-card
          no-body
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
                icon="LockIcon"
                size="18"
            />
            <span class="align-middle ml-50">实时状态</span>
          </b-card-title>
        </b-card-header>
        <b-card-body>
          <b-list-group>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span>当前阶段</span>
              <b-badge
                  variant="primary"
                  pill
                  class="badge-round"
              >
                <div v-if="awards.stage === 1">
                  资格审核
                </div>
                <div v-if="awards.stage === 2">
                  初评阶段
                </div>
                <div v-if="awards.stage === 3">
                  终评阶段
                </div>
                <div v-if="awards.stage === 4">
                  确认阶段
                </div>
              </b-badge>

            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span>当前轮次</span>
              <b-badge
                  variant="primary"
                  pill
                  class="badge-round"
              >
                {{ awards.round }}
              </b-badge>

            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span>当前限制总票数</span>
              <b-badge
                  variant="primary"
                  pill
                  class="badge-round"
              >
                {{ awards.round }}
              </b-badge>

            </b-list-group-item>

          </b-list-group>
        </b-card-body>
      </b-card>
    </b-col>

    <b-col md="3">
      <b-card
          no-body
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
                icon="LockIcon"
                size="18"
            />
            <span class="align-middle ml-50">信息数据</span>
          </b-card-title>
        </b-card-header>
        <b-card-body>
          <b-list-group>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span>主题</span>
              {{ awards.title }}
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span>终评限额</span>
              <b-badge
                  variant="primary"
                  pill
                  class="badge-round"
              >
                {{ awards.appraise_count }}
              </b-badge>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span>定岗名额</span>
              <b-badge
                  variant="primary"
                  pill
                  class="badge-round"
              >
                {{ awards.count }}
              </b-badge>
            </b-list-group-item>

          </b-list-group>
        </b-card-body>
      </b-card>
    </b-col>


   </b-row>

    <b-card
        no-body
        class="mb-0"
    >
      <b-card-header class="p-1">
      <b-card-title class="font-medium-2">
        <feather-icon
            icon="LockIcon"
            size="18"
        />
        <span class="align-middle ml-50">投票列表</span>
      </b-card-title>
    </b-card-header>
            <b-table
                striped
                hover
                fixed
                sticky-header
                :items="items"
                :fields="fields"
                style="min-height:700px"
            >
              <template #cell(actions)="row">
                <b-form-radio-group
                    :options="radioLists"
                    button-variant="outline-info"
                    buttons
                    @input="selectRadio"
                    @change="selectCurrRadio(row.index,row.item)"
                    required
                >

                </b-form-radio-group>

              </template>
              <template #cell(duty_name)="row">
                {{row.item.duty_name+row.item.duty_lv_name}}1

              </template>
            </b-table>

        <b-row>
          <b-col
              offset-md="5"
          >
            <b-form-group

                label-for="search"
            >
              <b-button
                  variant="primary"
                  class="mr-2"
                  type="button"
                  @click="saveAppraise"
                  v-if="showSave"
              >确认提交</b-button>



            </b-form-group>
          </b-col>
      </b-row>

    </b-card>


  </div>
</template>


<script>
import {
  BCard, BRow, BCol, BTable, VBToggle, BButton, BBadge, BModal, BCardBody, BImg, VBTooltip, BAlert, BDropdown, BDropdownItem,
  BFormGroup,BFormRadioGroup,BCardHeader,BCardTitle,BMediaBody,BMedia,BMediaAside,BAvatar,BCardText,BSidebar,BFormInput, BListGroup, BListGroupItem,BFormRadio
} from 'bootstrap-vue'
import 'animate.css'

import ImgLists from "@/views/awards/ImgLists.vue";
import vSelect from 'vue-select'
export default {
  name: "appraise",
  components: {
    BAvatar,
    BBadge,
    BCard,
    BTable,
    BRow,
    BCol,
    BButton,
    BModal,
    BCardBody,
    BImg,
    BAlert,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BCardText,
    ImgLists,
    BFormInput,
    BSidebar,
    BCardHeader,BCardTitle,
    BMediaBody,BMedia,BMediaAside,
    BListGroup, BListGroupItem,BFormRadio,BFormRadioGroup

  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,

  },
  data() {
    return {
      awards_id:Number(this.$route.query.id),
      pdf_url:"",
      confirm:0,
      request_id:0,
      fields:[
        {key: 'name', label: '姓名',},
        {key: 'sex_name', label: '性别',},
        {key: 'department_name', label: '部门' },
        {key: 'duty_name', label: '竞聘岗位',},
        {key: 'actions', label: '投票' },
      ],
      radioLists:[{text:"我同意",value:1},{text:"不同意",value:2}],
      items: [],
      currRadio:0,
      timer:null,
      awards: {
        affirm_status:0,
        round:0,
      },
      voteLists:[],
      showSave:true,
      showViewData:{},


    }
  },

  created() {
    console.log(this.$route.query.id)
    this.getAwards()
    this.setTimes()


  },

  destroyed() {
    // 每次离开当前界面时，清除定时器
    clearInterval(this.timer)
    this.timer = null
    console.log('清除定时器')
  },

  watch:{
      'awards.round':{
        handler(newVal,oldVal){
          if (newVal !== oldVal){
            console.log(this.awards)
            console.log(this.awards.affirm_status)
            if (this.awards.affirm_status === 0){
              this.showToast(200,'进入第 '+newVal+" 轮投票")
              this.getAwardsAppraiseLists()
                this.voteLists = []
                this.showSave = true
            }
          }
        }
      },
    'awards.affirm_status':{
        handler(newVal,oldVal){
        if (newVal === 1){
          this.showToast(200,'准备进入确认轮投票')
          clearInterval(this.timer)
          this.timer = null
          this.$router.push({ path: '/awards-vote', query: { awards_id: this.awards.id ,quota_type: this.awards.quota_type} })
          //this.getAwardsAppraiseLists()
        }
    }},


  },

  methods:{


    selectRadio(e){
      this.currRadio = e
      console.log(e)
      // row._rowVariant = 'danger'
      // this.$set(this.items, index, row)
    },

    selectCurrRadio(index,row){
      var vIndex = this.voteLists.findIndex((n) => n.id === row.id )
      if (vIndex === -1){
        this.voteLists.push({id:row.id,r_tid:row.tid,radio:this.currRadio})
      }else{
        this.$set(this.voteLists, index, {id:row.id,r_tid:row.tid,radio:this.currRadio})
      }
      console.log(this.voteLists)
      if (this.currRadio === 1){
        row._rowVariant = 'success'
      }else{
        row._rowVariant = 'danger'
      }
      this.$set(this.items, index, row)

    },



    getAwardsAppraiseLists(){
      this.AwardsStudentLists({awards_id:this.awards_id,round:this.awards.round,}).then(res =>{
        this.items = res.data.data



      })
    },

    getAwards(){
      this.AwardsGet({id:this.awards_id}).then(res =>{
        this.awards = res.data.data


      })
    },




    setTimes() {
      if (this.timer === null) {
        this.timer = setInterval(() => {
          console.log('定时器运行')
          this.getAwards()
        }, 10000)
      }
    },







    saveAppraise(){


      this.awardsAppraise({"awards_id":this.awards.id,"round":this.awards.round,"data":this.voteLists,"stage":this.awards.stage}).then(res =>{
        this.showToast(res.data.code,res.data.msg)
        if (res.data.code === 200){
          this.showSave = false
          this.$swal({
            title: '操作成功',
            icon: 'success',
            text: res.data.msg +"(此消息将会在5秒内自动关闭)",
            timer: 5000,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            showClass: {
              popup: 'animate__animated animate__flipInX',
            },
            buttonsStyling: false,
          })
        }
      })
    },

    show_request(url,id){

      this.img_url =  url
      this.request_id = id
      this.showStudentRequest = true
    },



  },
}
</script>

